<template>
  <div class="message">
    <div class="msg-list">
      <template v-if="arr.length > 0">
        <van-swipe-cell v-for="item in arr" :key="item.mid">
          <div class="item" @click="goRoom(item)">
            <div class="avatar">
              <van-image
                round
                class="img"
                :src="item.headImgUrl"
              />
              <div v-if="item.unReadCount" class="num">{{ item.unReadCount }}</div>
            </div>
            <div class="cont">
              <div class="title">
                <div class="name">{{ item.realName || item.username }}</div>
                <div class="date">{{ item.time }}</div>
              </div>
              <template v-if="item.msg.ext.type == '7'">
                <div v-if="item.msg.data[0].data" class="des">[面谈]</div>
              </template>
              <template v-else-if="item.msg.ext.type == '8'">
                <div v-if="item.msg.data[0].data" class="des">[服务协议]</div>
              </template>
              <template v-else-if="item.msg.ext.type == '9'">
                <div v-if="item.msg.data[0].data" class="des">[初审材料]</div>
              </template>
              <template v-else-if="item.msg.ext.type == '12'">
                <div v-if="item.msg.data[0].data" class="des">[面签材料]</div>
              </template>
              <template v-else-if="item.msg.ext.type == '13'">
                <div v-if="item.msg.data[0].data" class="des">[贷款方案]</div>
              </template>
              <template v-else-if="item.msg.ext.type == '14'">
                <div v-if="item.msg.data[0].data" class="des">[结束贷款确认]</div>
              </template>
              <template v-else-if="item.msg.ext.type == '18'">
                <div v-if="item.msg.data[0].data" class="des">[约面谈]</div>
              </template>
              <template v-else-if="item.msg.type == 'location'">
                <div class="des">[定位]</div>
              </template>
              <template v-else>
                <div v-if="item.msg.data[0].data" class="des">
                  {{ item.msg.data[0].data }}
                </div>
              </template>
            </div>
          </div>
          <template #right>
            <div class="right-cont">
              <!-- <div class="btn" @click="goTop">置顶</div> -->
              <div class="btn del" @click="goDel(item)">删除</div>
            </div>
          </template>
        </van-swipe-cell>
      </template>
      <template v-else-if="!loading">
        <DefaultPage title="暂无会话消息~" style="padding-top: 200px;" />
      </template>
    </div>
  </div>
</template>

<script>
/* eslint-disable eqeqeq */
/* eslint-disable no-sequences */
import { getStorageInfo, getStorageSync } from '@/utils/index'
import disp from '@/utils/broadcast'
import { getOssUrl } from '@/utils/oss'
import DefaultPage from '@/components/DefaultPage'
import { mapGetters } from 'vuex'
export default {
  components: { DefaultPage },
  data() {
    return {
      arr: [],
      loading: true
    }
  },
  computed: {
    ...mapGetters([
      'userInfos'
    ])
  },
  async mounted() {
    this.loading = this.$toast.loading({ forbidClick: true, duration: 0 })
    const _this = this
    await this.getChatList()
    // 监听未读消息数
    disp.on('em.unreadspot', () => {
      _this.getChatList()
    })
  },
  methods: {
    goTop(item) {
      console.log(item, '置顶')
    },
    goDel(item) {
      const _this = this
      console.log(item, '删除')
      const nameList = { your: item.username }
      const myName = this.userInfos.eaUserName
      this.$dialog.confirm({
        message: `确认将${item.realName}从消息列表中删除`
      })
        .then(() => {
          localStorage.removeItem(nameList.your + myName)
          localStorage.removeItem('rendered_' + nameList.your + myName)
          disp.fire('em.chat.session.remove')
          _this.getChatList()
        })
        .catch(() => {
          // on cancel
        })
    },
    goRoom(item) {
      this.$router.push({ name: 'chatRoom', params: { your: item.username }})
    },
    getChatList() {
      const myName = this.userInfos.eaUserName
      const me = this
      const storageKeys = getStorageInfo()
      const newChatMsgKeys = []
      const historyChatMsgKeys = []
      const len = myName.length
      storageKeys.forEach((item) => {
        if (item.slice(-len) == myName && item.indexOf('rendered_') == -1) {
          newChatMsgKeys.push(item)
        } else if (
          item.slice(-len) == myName &&
          item.indexOf('rendered_') > -1
        ) {
          historyChatMsgKeys.push(item)
        } else if (item === 'INFORM') {
          newChatMsgKeys.push(item)
        }
      })
      cul.call(me, newChatMsgKeys, historyChatMsgKeys)

      async function cul(newChatMsgKeys, historyChatMsgKeys) {
        const array = []
        let lastChatMsg

        for (let i = historyChatMsgKeys.length; i > 0, i--;) {
          const index = newChatMsgKeys.indexOf(historyChatMsgKeys[i].slice(9))
          if (index > -1) {
            const newChatMsgs = getStorageSync(newChatMsgKeys[index]) || []
            if (newChatMsgs.length) {
              lastChatMsg = newChatMsgs[newChatMsgs.length - 1]
              lastChatMsg.unReadCount = newChatMsgs.length
              if (lastChatMsg.unReadCount > 99) {
                lastChatMsg.unReadCount = '99+'
              }
              const dateArr = lastChatMsg.time.split(' ')[0].split('-')
              const timeArr = lastChatMsg.time.split(' ')[1].split(':')
              const month = dateArr[2] < 10 ? '0' + dateArr[2] : dateArr[2]
              lastChatMsg.dateTimeNum = `${dateArr[1]}${month}${timeArr[0]}${timeArr[1]}${timeArr[2]}`
              lastChatMsg.time = `${dateArr[1]}月${dateArr[2]}日 ${timeArr[0]}:${timeArr[1]}`
              newChatMsgKeys.splice(index, 1)
            } else {
              const historyChatMsgs = getStorageSync(historyChatMsgKeys[i])
              if (historyChatMsgs.length) {
                lastChatMsg = historyChatMsgs[historyChatMsgs.length - 1]
                const dateArr = lastChatMsg.time.split(' ')[0].split('-')
                const timeArr = lastChatMsg.time.split(' ')[1].split(':')
                const month = dateArr[2] < 10 ? '0' + dateArr[2] : dateArr[2]
                lastChatMsg.dateTimeNum = `${dateArr[1]}${month}${timeArr[0]}${timeArr[1]}${timeArr[2]}`
                lastChatMsg.time = `${dateArr[1]}月${dateArr[2]}日 ${timeArr[0]}:${timeArr[1]}`
              }
            }
          } else {
            const historyChatMsgs = getStorageSync(historyChatMsgKeys[i])
            if (historyChatMsgs.length) {
              lastChatMsg = historyChatMsgs[historyChatMsgs.length - 1]
              const dateArr = lastChatMsg.time.split(' ')[0].split('-')
              const timeArr = lastChatMsg.time.split(' ')[1].split(':')
              const month = dateArr[2] < 10 ? '0' + dateArr[2] : dateArr[2]
              lastChatMsg.dateTimeNum = `${dateArr[1]}${month}${timeArr[0]}${timeArr[1]}${timeArr[2]}`
              lastChatMsg.time = `${dateArr[1]}月${dateArr[2]}日 ${timeArr[0]}:${timeArr[1]}`
            }
          }
          if (
            lastChatMsg &&
            (lastChatMsg.chatType == 'groupchat' ||
              lastChatMsg.chatType == 'chatRoom')
          ) {
            lastChatMsg.groupName = me.groupName[lastChatMsg.info.to]
          }
          lastChatMsg.headImgUrl = await getOssUrl(lastChatMsg.headImgUrl)
          lastChatMsg && lastChatMsg.username != myName && array.push(lastChatMsg)
        }

        for (let i = newChatMsgKeys.length; i > 0, i--;) {
          const newChatMsgs = getStorageSync(newChatMsgKeys[i]) || []
          if (newChatMsgs.length) {
            lastChatMsg = newChatMsgs[newChatMsgs.length - 1]
            lastChatMsg.unReadCount = newChatMsgs.length
            if (lastChatMsg.unReadCount > 99) {
              lastChatMsg.unReadCount = '99+'
            }
            const dateArr = lastChatMsg.time.split(' ')[0].split('-')
            const timeArr = lastChatMsg.time.split(' ')[1].split(':')
            const month = dateArr[2] < 10 ? '0' + dateArr[2] : dateArr[2]
            lastChatMsg.dateTimeNum = `${dateArr[1]}${month}${timeArr[0]}${timeArr[1]}${timeArr[2]}`
            lastChatMsg.time = `${dateArr[1]}月${dateArr[2]}日 ${timeArr[0]}:${timeArr[1]}`
            if (
              lastChatMsg.chatType == 'groupchat' ||
              lastChatMsg.chatType == 'chatRoom'
            ) {
              lastChatMsg.groupName = me.groupName[lastChatMsg.info.to]
            }
            lastChatMsg.headImgUrl = await getOssUrl(lastChatMsg.headImgUrl)
            lastChatMsg.username != myName && array.push(lastChatMsg)
          }
        }

        array.sort((a, b) => {
          return b.dateTimeNum - a.dateTimeNum
        })
        this.arr = array
        this.loading && this.loading.clear()
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.message {
  min-height: 100%;
  .msg-list {
    height: 100%;
    .item {
      padding: 15px;
      display: flex;
      border-bottom: 1px solid #F3F3F5;
      .avatar {
        position: relative;
        .img {
          width: 50px;
          height: 50px;
        }
        .num {
          position: absolute;
          top: 0;
          right: 0;
          line-height: 14px;
          width: 14px;
          height: 14px;
          font-size: 10px;
          text-align: center;
          color: #fff;
          border: 1px solid #fff;
          border-radius: 100%;
          background: #FF5E2A;
        }
      }
      .cont {
        flex: 1;
        padding-left: 10px;
        .title {
          display: flex;
          justify-content: space-between;
          padding-top: 8px;
          font-size: 16px;
          color: #2E3135;
        }
        .des {
          width: 280px;
          padding-top: 8px;
          font-size: 14px;
          color: #999999;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .date {
        font-size: 10px;
        color: #CCCCCC;
      }
    }
    .right-cont {
      display: flex;
      height: 100%;
      .btn {
        display: flex;
        align-items: center;
        padding: 0 20px;
        font-size: 14px;
        color: #fff;
        background: #32465A;
        &.del {
          background: #F86767;
        }
      }
    }
  }
}
</style>
